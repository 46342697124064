<template>
  <div class="privacy">
    <!-- <p style="margin-top: auto; margin-bottom: auto; text-align: center">
      <strong
        ><span style="font-size: 24px; font-family: 汉仪旗黑-80S, sans-serif"
          >隐私政策</span
        ></strong
      >
    </p> -->
    <p style="text-indent: 32px">
      <span style="font-size: 18px; font-family: 汉仪旗黑-80S, sans-serif"
        >发布日期：【2023】年【5】月【31】日</span
      >
    </p>
    <p style="text-indent: 32px">
      <span style="font-size: 18px; font-family: 汉仪旗黑-80S, sans-serif"
        >生效日期：【2023】年【5】月【31】日</span
      >
    </p>
    <p style="text-indent: 32px">
      <span style="font-size: 18px; font-family: 汉仪旗黑-80S, sans-serif"
        >欢迎您使用我们的产品和服务！中智薪税技术服务有限公司（以下称“我们”）深知个人信息对您的重要性，您的信赖对我们非常重要，我们将严格遵守法律法规要求采取相应的安全保护措施，致力于保护您的个人信息安全。您在使用我们的服务时，我们可能会收集和使用您的相关信息。我们希望通过《隐私政策》（“本隐私政策”）向您说明我们在您使用我们的服务时如何收集、使用、共享、存储和保护您的个人信息，以及您可以如何管理您的个人信息。
    </span>
    </p>
    <p style="text-indent: 32px">
      <span style="font-size: 18px; font-family: 汉仪旗黑-80S, sans-serif"
        >在您开始使用我们的产品和服务前，请您务必先仔细阅读和理解本政策，特别应重点阅读我们以<strong>粗体/粗体下划线</strong>标识的条款，确保您充分理解和同意后再开始使用。本政策中涉及的专业词汇，我们尽量以简明通俗的表述向您解释，以便于您理解。</span
      >
    </p>
    <p style="text-indent: 32px">
      <span style="font-size: 18px; font-family: 汉仪旗黑-80S, sans-serif"
        >本政策将帮助您了解以下内容：</span
      >
    </p>
    <p style="text-indent: 32px">
      <span style="font-size: 18px; font-family: 汉仪旗黑-80S, sans-serif"
        >一、定义及适用范围</span
      >
    </p>
    <p style="text-indent: 32px">
      <span style="font-size: 18px; font-family: 汉仪旗黑-80S, sans-serif"
        >二、我们如何收集和使用您的个人信息</span
      >
    </p>
    <p style="text-indent: 32px">
      <span style="font-size: 18px; font-family: 汉仪旗黑-80S, sans-serif"
        >三、我们如何共享、转让、公开披露您的个人信息</span
      >
    </p>
	<p style="text-indent: 32px">
	  <span style="font-size: 18px; font-family: 汉仪旗黑-80S, sans-serif"
	    >四、我们如何保护您的个人信息</span
	  >
	</p>
    <p style="text-indent: 32px">
      <span style="font-size: 18px; font-family: 汉仪旗黑-80S, sans-serif"
        >五、我们如何存储您的信息</span
      >
    </p>
    <p style="text-indent: 32px">
      <span style="font-size: 18px; font-family: 汉仪旗黑-80S, sans-serif"
        >六、您如何管理您的信息</span
      >
    </p>
    <p style="text-indent: 32px">
      <span style="font-size: 18px; font-family: 汉仪旗黑-80S, sans-serif"
        >七、我们如何处理未成年人的信息</span
      >
    </p>
    <p style="text-indent: 32px">
      <span style="font-size: 18px; font-family: 汉仪旗黑-80S, sans-serif"
        >八、本政策如何更新</span
      >
    </p>
    <p style="text-indent: 32px">
      <span style="font-size: 18px; font-family: 汉仪旗黑-80S, sans-serif"
        >九、您如何联系我们</span
      >
    </p>
    <p style="text-indent: 32px">
      <strong
        ><span style="font-size: 18px; font-family: 汉仪旗黑-80S, sans-serif"
          >一、定义及适用范围</span
        ></strong
      >
    </p>
    <p style="text-indent: 32px">
      <strong
        ><span style="font-size: 18px; font-family: 汉仪旗黑-80S, sans-serif"
          >（一）定义</span
        ></strong
      >
    </p>
    <p style="text-indent: 32px">
      <span style="font-size: 18px; font-family: 汉仪旗黑-80S, sans-serif"
        >个人信息：指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。</span
      >
    </p>
    <p style="text-indent: 32px">
      <span style="font-size: 18px; font-family: 汉仪旗黑-80S, sans-serif"
        >关联公司：是指与中智薪税技术服务有限公司存在关联关系的其他公司。包括不限于：全资子公司、控股公司、参股公司、控股股东、控股股东实际控制的其他企业。</span
      >
    </p>
    <p style="text-indent: 32px">
      <span style="font-size: 18px; font-family: 汉仪旗黑-80S, sans-serif"
        >个人敏感信息：指包括身份证件号码、个人生物识别信息、银行账号、财产信息、行踪轨迹、交易信息、14岁以下（含）儿童信息等的个人信息（我们将在本政策中对具体个人敏感信息以粗体进行显著标识）。</span
      >
    </p>
    <p style="text-indent: 32px">
      <span style="font-size: 18px; font-family: 汉仪旗黑-80S, sans-serif"
        >儿童：指不满十四周岁的未成年人。</span
      >
    </p>
    <p style="text-indent: 32px">
      <span style="font-size: 18px; font-family: 汉仪旗黑-80S, sans-serif"
        >匿名化：通过对个人信息的技术处理，使得个人信息主体无法被识别或者关联，且处理后的信息不能被复原的过程。（注：个人信息经匿名化处理后所得的信息不属于个人信息。）</span
      >
    </p>
    <p style="text-indent: 32px">
      <span style="font-size: 18px; font-family: 汉仪旗黑-80S, sans-serif"
        >去标识化：通过对个人信息的技术处理，使其在不借助额外信息的情况下，无法识别或者关联个人信息主体的过程。 （注：去标识化建立在个体基础之上，保留了个体颗粒度，采用假名、加密、哈希函数等技术手段替代对个人信息的标识。）</span
      >
    </p>
    <p style="text-indent: 32px">
      <strong
        ><span style="font-size: 18px; font-family: 汉仪旗黑-80S, sans-serif"
          >（二）适用范围</span
        ></strong
      >
    </p>
    <p style="text-indent: 32px">
      <span style="font-size: 18px; font-family: 汉仪旗黑-80S, sans-serif"
        >本政策适用我们及关联公司以中智薪税公众服务号（http://www.ciicfc.com/）向您提供的各项产品和服务。包括：薪税相关政策浏览、咨询等功能。</span
      >
    </p>
    <p style="text-indent: 32px">
      <strong
        ><span style="font-size: 18px; font-family: 汉仪旗黑-80S, sans-serif"
          >二、我们如何收集和使用您的个人信息</span
        ></strong
      >
    </p>
    <p style="text-indent: 32px">
      <span style="font-size: 18px; font-family: 汉仪旗黑-80S, sans-serif"
        >为了向您提供产品和服务并不断将其进行优化，保障账号安全，我们会按照如下方式收集、使用您的个人信息：</span
      >
    </p>
    <p style="text-indent: 32px">
      <strong
        ><span style="font-size: 18px; font-family: 汉仪旗黑-80S, sans-serif"
          >（一）使用服务所必须的功能</span
        ></strong
      >
    </p>
    <p style="text-indent: 32px">
      <span style="font-size: 18px; font-family: 汉仪旗黑-80S, sans-serif">1. 用户登录</span
      >
    </p>
    <p style="text-indent: 32px">
      <span style="font-size: 18px; font-family: 汉仪旗黑-80S, sans-serif"
      >当第一次使用中智薪税服务号的工资或年终奖计算器时，需要以<strong>手机验证码或微信授权</strong>方式进行登录。</span
      >
    </p>
    <p style="text-indent: 32px">
      <span style="font-size: 18px; font-family: 汉仪旗黑-80S, sans-serif">2. 浏览信息</span
      >
    </p>
    <p style="text-indent: 32px">
      <span style="font-size: 18px; font-family: 汉仪旗黑-80S, sans-serif"
        >该平台服务号其他栏目均为浏览信息界面和进入薪税公司其他平台的入口，不涉及个人信息收集。</span
      >
    </p>
    <p style="text-indent: 32px">
      <strong
        ><span style="font-size: 18px; font-family: 汉仪旗黑-80S, sans-serif"
          >（二）收集和使用个人信息的其他规则</span
        ></strong
      >
    </p>
    <p style="text-indent: 32px">
      <span style="font-size: 18px; font-family: 汉仪旗黑-80S, sans-serif">1. 若我们将信息用于本政策未载明的其他用途，或者将基于特定目的收集而来的信息用于其他目的，均会事先获得您或者您授权的所在单位的同意。我们收集及使用上述信息的目的是为了更好地提供服务，并且会通过包括但不限于网站通知、电子邮件、电话或短信息等方法通知。</span
      >
    </p>
    <p style="text-indent: 32px">
      <span style="font-size: 18px; font-family: 汉仪旗黑-80S, sans-serif">2.征得授权同意的例外</span
      >
    </p>
      <p style="text-indent: 32px">
          <span style="font-size: 18px; font-family: 汉仪旗黑-80S, sans-serif">根据相关法律法规规定，我们在以下情况下收集、使用您的个人信息无需您的授权同意：</span>
      </p>
      <p style="text-indent: 32px">
          <span style="font-size: 18px; font-family: 汉仪旗黑-80S, sans-serif">（1）与我们履行法律、法规规定的义务相关的；</span>
      </p>
      <p style="text-indent: 32px">
          <span style="font-size: 18px; font-family: 汉仪旗黑-80S, sans-serif">（2）与国家安全、国防安全直接相关的；</span>
      </p>
      <p style="text-indent: 32px">
          <span style="font-size: 18px; font-family: 汉仪旗黑-80S, sans-serif">（3）与公共安全、公共卫生、重大公共利益直接相关的；</span>
      </p>
      <p style="text-indent: 32px">
          <span style="font-size: 18px; font-family: 汉仪旗黑-80S, sans-serif">（4）与犯罪侦查、起诉、审判和判决执行等司法或行政执法直接相关的；</span>
      </p>
      <p style="text-indent: 32px">
          <span style="font-size: 18px; font-family: 汉仪旗黑-80S, sans-serif">（5）出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；</span>
      </p>
      <p style="text-indent: 32px">
          <span style="font-size: 18px; font-family: 汉仪旗黑-80S, sans-serif">（6）您自行向社会公众公开的个人信息；</span>
      </p>
      <p style="text-indent: 32px">
          <span style="font-size: 18px; font-family: 汉仪旗黑-80S, sans-serif">（7）从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道；</span>
      </p>
      <p style="text-indent: 32px">
          <span style="font-size: 18px; font-family: 汉仪旗黑-80S, sans-serif">（8）法律法规规定的其他情形。</span>
      </p>
    <p style="text-indent: 32px">
      <strong
        ><span style="font-size: 18px; font-family: 汉仪旗黑-80S, sans-serif"
          >三、我们如何共享、转让、公开披露您的个人信息</span
        ></strong
      >
    </p>
    <p style="text-indent: 32px">
      <strong
        ><span style="font-size: 18px; font-family: 汉仪旗黑-80S, sans-serif"
          >（一）共享</span
        ></strong
      >
    </p>
    <p style="text-indent: 32px">
      <span style="font-size: 18px; font-family: 汉仪旗黑-80S, sans-serif"
        >我们不会与本产品和服务以外的任何公司、组织和个人分享您的个人信息，但以下情况除外：</span
      >
    </p>
    <p style="text-indent: 32px">
      <span style="font-size: 18px; font-family: 汉仪旗黑-80S, sans-serif">1</span
      ><span style="font-size: 18px; font-family: 汉仪旗黑-80S, sans-serif"
        >．在获取明确同意的情况下共享：获得您的明确同意后，我们会与其他方共享您的个人信息。</span
      >
    </p>
    <p style="text-indent: 32px">
      <span style="font-size: 18px; font-family: 汉仪旗黑-80S, sans-serif">2</span
      ><span style="font-size: 18px; font-family: 汉仪旗黑-80S, sans-serif"
        >．我们可能会根据法律法规规定，或按政府主管部门的强制性要求，对外共享您的个人信息。</span
      >
    </p>
    <p style="text-indent: 32px">
      <span style="font-size: 18px; font-family: 汉仪旗黑-80S, sans-serif">3</span
      ><span style="font-size: 18px; font-family: 汉仪旗黑-80S, sans-serif"
        >．与授权合作伙伴共享：我们可能委托授权合作伙伴为您提供某些服务或代表我们履行职能，我们仅会出于本政策声明的合法、正当、必要、特定、明确的目的共享您的信息，授权合作伙伴只能接触到其履行职责所需信息，且我们将会通过协议要求其不得将此信息用于其他任何目的。
</span
      >
    </p>
    <p style="text-indent: 32px">
      <span style="font-size: 18px; font-family: 汉仪旗黑-80S, sans-serif">目前，我们的授权合作伙伴包括以下方面：</span
      >
    </p>
    <p style="text-indent: 32px">
      <span style="font-size: 18px; font-family: 汉仪旗黑-80S, sans-serif">（1）该平台提供了第三方小鹅通链接，您可使用小鹅通平台免费获取薪税相关课程。</span
      >
    </p>
    <p style="text-indent: 32px">
      <span style="font-size: 18px; font-family: 汉仪旗黑-80S, sans-serif">（2）该平台的短信是通过阿里云的短信通知服务统一发送。</span
      >
    </p>
    <p style="text-indent: 32px">
      <span style="font-size: 18px; font-family: 汉仪旗黑-80S, sans-serif">如您需进一步了解服务提供商和其他合作伙伴信息，请通过本政策第九节中的联系方式与我们联系，我们会尽快作出解答。</span
      >
    </p>
    <p style="text-indent: 32px">
      <strong
        ><span style="font-size: 18px; font-family: 汉仪旗黑-80S, sans-serif"
          >（二）转让</span
        ></strong
      >
    </p>
    <p style="text-indent: 32px">
      <span style="font-size: 18px; font-family: 汉仪旗黑-80S, sans-serif"
        >我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：</span
      >
    </p>
    <p style="text-indent: 32px">
      <span style="font-size: 18px; font-family: 汉仪旗黑-80S, sans-serif">1</span
      ><span style="font-size: 18px; font-family: 汉仪旗黑-80S, sans-serif"
        >．在获取明确同意的情况下转让：获得您的明确同意后，我们会向其他方转让您的个人信息；</span
      >
    </p>
    <p style="text-indent: 32px">
      <span style="font-size: 18px; font-family: 汉仪旗黑-80S, sans-serif">2</span
      ><span style="font-size: 18px; font-family: 汉仪旗黑-80S, sans-serif"
        >．在我们涉及合并、收购或破产清算时，如涉及到个人信息转让，我们会要求新的持有您个人信息的公司、组织继续受此隐私政策的约束，否则我们将要求该公司、组织和个人重新向您征求授权同意。</span
      >
    </p>
    <p style="text-indent: 32px">
      <strong
        ><span style="font-size: 18px; font-family: 汉仪旗黑-80S, sans-serif"
          >（三）公开披露</span
        ></strong
      >
    </p>
    <p style="text-indent: 32px">
      <span style="font-size: 18px; font-family: 汉仪旗黑-80S, sans-serif"
        >我们仅会在以下情况下，公开披露您的个人信息：</span
      >
    </p>
    <p style="text-indent: 32px">
      <span style="font-size: 18px; font-family: 汉仪旗黑-80S, sans-serif">1</span
      ><span style="font-size: 18px; font-family: 汉仪旗黑-80S, sans-serif"
        >．获得您明确同意或基于您的主动选择，我们可能会公开披露您的个人信息；</span
      >
    </p>
    <p style="text-indent: 32px">
      <span style="font-size: 18px; font-family: 汉仪旗黑-80S, sans-serif">2</span
      ><span style="font-size: 18px; font-family: 汉仪旗黑-80S, sans-serif"
        >．基于法律的披露：在法律、法律程序、诉讼或政府主管部门强制性要求的情况下，我们可能会公开披露您的个人信息。</span
      >
    </p>
    <p style="text-indent: 32px">
      <strong
        ><span style="font-size: 18px; font-family: 汉仪旗黑-80S, sans-serif"
          >（四）共享、转让、公开披露个人信息时事先征得授权同意的例外</span
        ></strong
      >
    </p>
    <p style="text-indent: 32px">
      <span style="font-size: 18px; font-family: 汉仪旗黑-80S, sans-serif"
        >根据相关法律法规规定，以下情形中，共享、转让、公开披露您的个人信息无需事先征得您的授权同意：</span
      >
    </p>
    <p style="text-indent: 32px">
      <span style="font-size: 18px; font-family: 汉仪旗黑-80S, sans-serif">1</span
      ><span style="font-size: 18px; font-family: 汉仪旗黑-80S, sans-serif"
        >、与我们履行法律法规规定的义务相关的；</span
      >
    </p>
    <p style="text-indent: 32px">
      <span style="font-size: 18px; font-family: 汉仪旗黑-80S, sans-serif">2</span
      ><span style="font-size: 18px; font-family: 汉仪旗黑-80S, sans-serif"
        >、与国家安全、国防安全直接相关的；</span
      >
    </p>
    <p style="text-indent: 32px">
      <span style="font-size: 18px; font-family: 汉仪旗黑-80S, sans-serif">3</span
      ><span style="font-size: 18px; font-family: 汉仪旗黑-80S, sans-serif"
        >、与公共安全、公共卫生、重大公共利益直接想关的；</span
      >
    </p>
    <p style="text-indent: 32px">
      <span style="font-size: 18px; font-family: 汉仪旗黑-80S, sans-serif">4</span
      ><span style="font-size: 18px; font-family: 汉仪旗黑-80S, sans-serif"
        >、与犯罪侦查、起诉、审判和判决执行等司法或行政执法直接相关的；</span
      >
    </p>
    <p style="text-indent: 32px">
      <span style="font-size: 18px; font-family: 汉仪旗黑-80S, sans-serif">5</span
      ><span style="font-size: 18px; font-family: 汉仪旗黑-80S, sans-serif"
        >、出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；</span
      >
    </p>
    <p style="text-indent: 32px">
      <span style="font-size: 18px; font-family: 汉仪旗黑-80S, sans-serif">6</span
      ><span style="font-size: 18px; font-family: 汉仪旗黑-80S, sans-serif"
        >、您自行向社会公众公开的个人信息；</span
      >
    </p>
    <p style="text-indent: 32px">
      <span style="font-size: 18px; font-family: 汉仪旗黑-80S, sans-serif">7</span
      ><span style="font-size: 18px; font-family: 汉仪旗黑-80S, sans-serif"
        >、从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道。</span
      >
    </p>
    <p style="text-indent: 32px">
      <strong
        ><span style="font-size: 18px; font-family: 汉仪旗黑-80S, sans-serif"
          >四、我们如何保护您的个人信息</span
        ></strong
      >
    </p>
    <p style="text-indent: 32px">
      <strong
        ><span style="font-size: 18px; font-family: 汉仪旗黑-80S, sans-serif"
          >（一）数据安全技术措施</span
        ></strong
      >
    </p>
    <p style="text-indent: 32px">
      <span style="font-size: 18px; font-family: 汉仪旗黑-80S, sans-serif"
        >我们会采用符合业界标准的安全防护措施，安全技术解决方案来防止您的信息遭到未经授权的访问、使用、篡改,避免数据的损坏丢失或泄露。网络服务采取了多种加密技术，我们将利用加密传输技术（SSL）来保护您的信息，采取加密技术对您的账户信息进行加密保存，并通过隔离技术进行隔离。</span
      >
    </p>
    <p style="text-indent: 32px">
      <span style="font-size: 18px; font-family: 汉仪旗黑-80S, sans-serif"
        >目前，我们在信息安全方面已达到ISO/IEC 27001、ISO/IEC 27701、信息安全等级保护（三级）等国际及国内权威认证标准的要求，并已获得了相应的认证。</span
      >
    </p>
    <p style="text-indent: 32px">
      <strong
        ><span style="font-size: 18px; font-family: 汉仪旗黑-80S, sans-serif"
          >（二）我们采取的数据保护管理组织措施</span
        ></strong
      >
    </p>
    <p style="text-indent: 32px">
      <span style="font-size: 18px; font-family: 汉仪旗黑-80S, sans-serif"
        >我们通过从组织建设、制度设计、人员管理、产品技术等方面多维度提升个人信息的安全性。同时，我们仅允许有必要知晓这些信息的员工访问您的信息，并为此设置了严格的访问权限控制和监控机制。对于关键岗位员工录用前我们都会经过背景调查，我们同时要求可能接触到您的信息的所有人员签订保密协议并履行相应的保密义务。我们也会定期开展关于个人信息和隐私保护的相关培训，不断提升员工对于用户个人信息和隐私的保护意识。</span
      >
    </p>
    <p style="text-indent: 32px">
      <strong
        ><span style="font-size: 18px; font-family: 汉仪旗黑-80S, sans-serif"
          >（三）账户安全风险的自主防范</span
        ></strong
      >
    </p>
    <p style="text-indent: 32px">
      <span style="font-size: 18px; font-family: 汉仪旗黑-80S, sans-serif"
        >互联网并非绝对安全的环境，请您妥善保护自己的个人信息，仅在必要的情形下向他人提供。如您发现自己的个人信息尤其是您的账户或密码发生泄露，请您立即联络中智客服或通过本政策第九节中的联络方式向我们联系，以便我们根据您的申请采取相应措施。请使用复杂密码，协助我们保证您的账号安全。我们将尽力确保或担保您发送给我们的任何信息的安全性。如果我们的物理、技术、或管理防护设施遭到破坏，导致信息被非授权访问、公开披露、篡改、或毁坏，导致您的合法权益受损，我们将承担相应的法律责任。</span
      >
    </p>
    <p style="text-indent: 32px">
      <strong
        ><span style="font-size: 18px; font-family: 汉仪旗黑-80S, sans-serif"
          >（四）个人信息安全事件处置</span
        ></strong
      >
    </p>
    <p style="text-indent: 32px">
      <span style="font-size: 18px; font-family: 汉仪旗黑-80S, sans-serif"
        >在不幸发生个人信息安全事件后，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们将及时将事件相关情况以邮件、信函、电话、推送通知等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况。</span
      >
    </p>
    <p style="text-indent: 32px">
      <strong
        ><span style="font-size: 18px; font-family: 汉仪旗黑-80S, sans-serif"
          >五、我们如何存储您的信息</span
        ></strong
      >
    </p>
    <p style="text-indent: 32px">
      <strong
        ><span style="font-size: 18px; font-family: 汉仪旗黑-80S, sans-serif"
          >（一）存储地点</span
        ></strong
      >
    </p>
    <p style="text-indent: 32px">
      <span style="font-size: 18px; font-family: 汉仪旗黑-80S, sans-serif"
        >我们仅在中国大陆地区收集和存放您的个人信息。因业务需要，将来如需跨境传输或存储的，我们将按照《中华人民共和国个人信息保护法》、《数据出境安全评估办法》的相关规定进行安全评估，并会向您告知境外接收方的名称或者姓名、联系方式、处理目的、处理方式、个人信息的种类以及您向境外接收方行使中华人民共和国相关法律法规规定权利的方式和程序等事项，并征得您的同意。</span
      >
    </p>
    <p style="text-indent: 32px">
      <strong
        ><span style="font-size: 18px; font-family: 汉仪旗黑-80S, sans-serif"
          >（二）存储期限</span
        ></strong
      >
    </p>
    <p style="text-indent: 32px">
      <strong
        ><span style="text-decoration: underline"
          ><span style="font-size: 18px; font-family: 汉仪旗黑-80S, sans-serif"
            >我们只会在达成本政策所述目的所需的期限内保留您的个人信息，除非法律有强制的留存要求。 </span
          ></span
        ></strong
      ><span style="font-size: 18px; font-family: 汉仪旗黑-80S, sans-serif"
        >&nbsp;</span
      ><span style="font-size: 18px; font-family: 汉仪旗黑-80S, sans-serif"
        >在超出保留期间后，我们会根据适用法律的要求删除您的个人信息，或使其匿名化处理。</span
      >
    </p>
    <p style="text-indent: 32px">
      <strong
        ><span style="font-size: 18px; font-family: 汉仪旗黑-80S, sans-serif"
          >六、您如何管理您的信息</span
        ></strong
      >
    </p>
    <p style="text-indent: 32px">
      <span style="font-size: 18px; font-family: 汉仪旗黑-80S, sans-serif"
        >您还可以通过以下方式访问及管理您的信息：</span
      >
    </p>
    <p style="text-indent: 32px">
      <strong
        ><span style="font-size: 18px; font-family: 汉仪旗黑-80S, sans-serif"
          >（一）查询、更正和补充您的信息</span
        ></strong
      >
    </p>
    <p style="text-indent: 32px">
      <span style="font-size: 18px; font-family: 汉仪旗黑-80S, sans-serif"
        >您有权查询、更正或补充您的信息。您可以通过本政策第九节中的联络方式向我们联系我们进行查询、更正和补充。</span
      >
    </p>
    <p style="text-indent: 32px">
      <strong
        ><span style="font-size: 18px; font-family: 汉仪旗黑-80S, sans-serif"
          >（二）删除您的个人信息</span
        ></strong
      >
    </p>
    <p style="text-indent: 32px">
      <span style="font-size: 18px; font-family: 汉仪旗黑-80S, sans-serif"
        >在以下情形中，您可以向我们提出删除个人信息的请求：</span
      >
    </p>
    <p style="text-indent: 32px">
      <span style="font-size: 18px; font-family: 汉仪旗黑-80S, sans-serif">A</span
      ><span style="font-size: 18px; font-family: 汉仪旗黑-80S, sans-serif"
        >．如果我们处理个人信息的行为违反法律法规；</span
      >
    </p>
    <p style="text-indent: 32px">
      <span style="font-size: 18px; font-family: 汉仪旗黑-80S, sans-serif">B</span
      ><span style="font-size: 18px; font-family: 汉仪旗黑-80S, sans-serif"
        >．如果我们收集、使用您的个人信息，却未征得您的同意；</span
      >
    </p>
    <p style="text-indent: 32px">
      <span style="font-size: 18px; font-family: 汉仪旗黑-80S, sans-serif">C</span
      ><span style="font-size: 18px; font-family: 汉仪旗黑-80S, sans-serif"
        >．如果我们处理个人信息的行为违反了与您的约定；</span
      >
    </p>
    <p style="text-indent: 32px">
      <span style="font-size: 18px; font-family: 汉仪旗黑-80S, sans-serif">D</span
      ><span style="font-size: 18px; font-family: 汉仪旗黑-80S, sans-serif"
        >．如果您不再使用我们的产品或服务；</span
      >
    </p>
    <p style="text-indent: 32px">
      <span style="font-size: 18px; font-family: 汉仪旗黑-80S, sans-serif">E</span
      ><span style="font-size: 18px; font-family: 汉仪旗黑-80S, sans-serif"
        >．如果我们不再为您提供产品或服务。</span
      >
    </p>
    <p style="text-indent: 32px">
      <span style="font-size: 18px; font-family: 汉仪旗黑-80S, sans-serif"
        >若我们决定响应您的删除请求，我们还将同时通知从我们获得您的个人信息的实体，要求其及时删除，除非法律法规另有规定，或这些实体获得您的独立授权。</span
      >
    </p>
    <p style="text-indent: 32px">
      <strong
        ><span style="text-decoration: underline"
          ><span style="font-size: 18px; font-family: 汉仪旗黑-80S, sans-serif"
            >当您自行或要求我们协助您删除相关信息后，因为适用的法律和安全技术限制，我们可能无法立即从备份系统中删除相应的信息，我们将安全地存储您的个人信息并限制对其的任何进一步的处理，直到备份可以清除或实现匿名化。</span
          ></span
        ></strong
      >
    </p>
    <p style="text-indent: 32px">
      <strong
        ><span style="font-size: 18px; font-family: 汉仪旗黑-80S, sans-serif"
          >（三）改变您授权同意的范围</span
        ></strong
      >
    </p>
    <p style="text-indent: 32px">
      <span style="font-size: 18px; font-family: 汉仪旗黑-80S, sans-serif"
        >您可以通过关闭设备功能改变您授权我们继续收集个人信息的范围或撤回您的授权。您还可以通过注销账户的方式，撤回我们继续收集您个人信息的全部授权。当您收回授权后，我们将不再处理相应的个人信息。但您收回授权的决定，有可能会影响我们此前基于您的授权而开展的个人信息处理。</span
      >
    </p>
    <p style="text-indent: 32px">
      <span style="font-size: 18px; font-family: 汉仪旗黑-80S, sans-serif"
        >您可以改变或者收回您授权我们收集和处理您的个人信息的范围，但请注意，对于部分类型的个人信息，例如为实现业务功能所必须的信息或者我们履行法律法规规定的义务所必需的信息，我们可能没有办法响应您改变授权范围的请求：</span
      >
    </p>
    <p style="text-indent: 32px">
      <span style="font-size: 18px; font-family: 汉仪旗黑-80S, sans-serif">1</span
      ><span style="font-size: 18px; font-family: 汉仪旗黑-80S, sans-serif"
        >、与我们履行法律法规规定的义务相关的；</span
      >
    </p>
    <p style="text-indent: 32px">
      <span style="font-size: 18px; font-family: 汉仪旗黑-80S, sans-serif">2</span
      ><span style="font-size: 18px; font-family: 汉仪旗黑-80S, sans-serif"
        >、与国家安全、国防安全直接相关的；</span
      >
    </p>
    <p style="text-indent: 32px">
      <span style="font-size: 18px; font-family: 汉仪旗黑-80S, sans-serif">3</span
      ><span style="font-size: 18px; font-family: 汉仪旗黑-80S, sans-serif"
        >、与公共安全、公共卫生、重大公共利益直接相关的；</span
      >
    </p>
    <p style="text-indent: 32px">
      <span style="font-size: 18px; font-family: 汉仪旗黑-80S, sans-serif">4</span
      ><span style="font-size: 18px; font-family: 汉仪旗黑-80S, sans-serif"
        >、与刑事犯罪侦查、起诉、审判和执行判决等直接相关的；</span
      >
    </p>
    <p style="text-indent: 32px">
      <span style="font-size: 18px; font-family: 汉仪旗黑-80S, sans-serif">5</span
      ><span style="font-size: 18px; font-family: 汉仪旗黑-80S, sans-serif"
        >、有充分证据表明个人信息主体存在主观恶意或滥用权利的；</span
      >
    </p>
    <p style="text-indent: 32px">
      <span style="font-size: 18px; font-family: 汉仪旗黑-80S, sans-serif">6</span
      ><span style="font-size: 18px; font-family: 汉仪旗黑-80S, sans-serif"
        >、出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人授权同意的；</span
      >
    </p>
    <p style="text-indent: 32px">
      <span style="font-size: 18px; font-family: 汉仪旗黑-80S, sans-serif">7</span
      ><span style="font-size: 18px; font-family: 汉仪旗黑-80S, sans-serif"
        >、响应您的请求将导致您或其他个人、组织的合法权益受到严重损害的；</span
      >
    </p>
    <p style="text-indent: 32px">
      <span style="font-size: 18px; font-family: 汉仪旗黑-80S, sans-serif">8</span
      ><span style="font-size: 18px; font-family: 汉仪旗黑-80S, sans-serif"
        >、涉及商业秘密的。</span
      >
    </p>
	<p style="text-indent: 32px">
	  <strong
	    ><span style="font-size: 18px; font-family: 汉仪旗黑-80S, sans-serif"
	      >七、我们如何处理未成年人的信息</span
	    ></strong
	  >
	</p>
    <p style="text-indent: 32px">
      <span style="font-size: 18px; font-family: 汉仪旗黑-80S, sans-serif"
        >我们的产品和服务主要面向成人。如您为未成年人，我们要求您请您的父母或其他监护人仔细阅读本政策，并在征得您的父母或其他监护人同意的前提下使用我们的服务或向我们提供信息。对于经监护人同意使用我们的产品或服务而收集未成年人个人信息的情况，我们只会在法律法规允许，监护人明确同意或者保护未成年人所必要的情况下使用，共享，转让或披露此信息。我们将根据国家相关法律法规及本隐私政策的规定保护未成年人的个人信息。</span
      >
    </p>
    <p style="text-indent: 32px">
      <strong
        ><span style="font-size: 18px; font-family: 汉仪旗黑-80S, sans-serif"
          >八、本政策如何更新</span
        ></strong
      >
    </p>
    <p style="text-indent: 32px">
      <span style="font-size: 18px; font-family: 汉仪旗黑-80S, sans-serif"
        >我们的服务以及技术不断跟随您以及其他的用户的需求更新和优化，因此在新的服务及业务流程变更时，我们可能会更新我们的隐私政策以告知您具体的变化。但未经您明确同意，我们不会限制您按照本政策所应享有的权利。我们会在专门页面上发布对本政策所做的任何变更。</span
      >
    </p>
    <p style="text-indent: 32px">
      <span style="font-size: 18px; font-family: 汉仪旗黑-80S, sans-serif"
        >对于重大变更，我们还会提供更为显著的通知（例如向您提供弹窗提示）。</span
      >
    </p>
    <p style="text-indent: 32px">
      <span style="font-size: 18px; font-family: 汉仪旗黑-80S, sans-serif"
        >本政策所指的重大变更包括但不限于：</span
      >
    </p>
    <p style="text-indent: 32px">
      <span style="font-size: 18px; font-family: 汉仪旗黑-80S, sans-serif">1</span
      ><span style="font-size: 18px; font-family: 汉仪旗黑-80S, sans-serif"
        >、我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等；</span
      >
    </p>
    <p style="text-indent: 32px">
      <span style="font-size: 18px; font-family: 汉仪旗黑-80S, sans-serif">2</span
      ><span style="font-size: 18px; font-family: 汉仪旗黑-80S, sans-serif"
        >、我们在控制权等方面发生重大变化。如并购重组等引起的信息控制者变更等；</span
      >
    </p>
    <p style="text-indent: 32px">
      <span style="font-size: 18px; font-family: 汉仪旗黑-80S, sans-serif">3</span
      ><span style="font-size: 18px; font-family: 汉仪旗黑-80S, sans-serif"
        >、个人信息共享、转让或公开披露的主要对象发生变化；</span
      >
    </p>
    <p style="text-indent: 32px">
      <span style="font-size: 18px; font-family: 汉仪旗黑-80S, sans-serif">4</span
      ><span style="font-size: 18px; font-family: 汉仪旗黑-80S, sans-serif"
        >、您拥有的个人信息处理方面的权利及其行使方式发生重大变化；</span
      >
    </p>
    <p style="text-indent: 32px">
      <span style="font-size: 18px; font-family: 汉仪旗黑-80S, sans-serif">5</span
      ><span style="font-size: 18px; font-family: 汉仪旗黑-80S, sans-serif"
        >、我们负责处理个人信息保护的责任部门、联络方式及投诉渠道发生变化；</span
      >
    </p>
    <p style="text-indent: 32px">
      <strong
        ><span style="font-size: 18px; font-family: 汉仪旗黑-80S, sans-serif"
          >九、如何联系我们</span
        ></strong
      >
    </p>
    <p style="text-indent: 32px">
      <span style="font-size: 18px; font-family: 汉仪旗黑-80S, sans-serif"
        >如果您对本隐私政策有任何疑问、意见或建议、投诉，请通过【发邮件至ciicfc@ciic.com.cn或拨打我们的客服电话（400-100-6621）、通信地址（北京市朝阳区光华路7号汉威大厦西区12层12B5，邮编：100004）】等多种方式与我们联系，我们将在15天内回复您的请求。</span
      >
    </p>
    <p>
      <span style="font-size: 16px; font-family: 汉仪中宋简">&nbsp;</span>
    </p>
    <p style="text-align: center">
      <span style="font-size: 18px; font-family: 汉仪旗黑-80S, sans-serif"></span
      ><span style="font-size: 18px; font-family: 汉仪旗黑-80S, sans-serif"
        ><br />
        </span
      >
    </p>
    <p>&nbsp;</p>
    <p>
      <span style="font-size: 18px; font-family: 汉仪旗黑-80S, sans-serif">历史版本：</span>
      <span style="font-size: 18px; font-family: 汉仪旗黑-80S, sans-serif" @click="showPopup('20220901')">《中智薪税隐私政策20220901》</span>
    </p>
  </div>
</template>
<style lang="scss" scoped>
.privacy {
  width: 100%;
  padding: 10px;
  background: #fff;
  font-size: 16px;
  overflow: hidden;
  .title {
    font-size: 20px;
    font-weight: bold;
    padding-bottom: 10px;
  }
  .date_p {
    padding-bottom: 8px;
    font-weight: bold;
  }
  .color_red {
    color: red;
  }
  h1 {
    font-weight: bold;
    padding: 10px 0;
  }
  strong {
    font-weight: bold;
  }
}
</style>
<script>
import {
Popup
} from "vant";
export default {
    components: {
        [Popup.name]: Popup
    },
    methods: {
        showPopup(type) {
            // this.show = true;
            if (type === "20220901") {
                this.$router.push("/login/privacy20220901");
            }
        },
    }
}
</script>